<template>
  <div style="padding: 10px">
    <v-subheader> Assessments to Complete </v-subheader>
    <v-list dense>
      <div
        v-if="
          filteredAssessmentRequests && filteredAssessmentRequests.length === 0
        "
        class=""
      >
        You're all caught up! No assessments need completing.
      </div>
      <v-list-item-group v-else color="primary">
        <div
          v-for="(assessment, index) in filteredAssessmentRequests"
          :key="index"
        >
          <v-list-item
            href="#"
            style="
              border-radius: 3px;
              cursor: pointer;
              border: 1px solid #d3d3d3;
              display: flex;
              color: #5b616a;
              margin-bottom: 5px;
            "
            aria-current="true"
            @click="openAssessment(assessment.id)"
          >
            <h3>{{ senderName(assessment.assessedId) }}</h3>
            <h5 style="margin-left: auto">
              Sent
              {{ assessment.dateCreated | moment("from", "now", true) }} ago
            </h5>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    assessments: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  computed: {
    filteredAssessmentRequests() {
      if (!this.assessments || this.assessments.length === 0) {
        return [];
      } else {
        return this.assessments
          .filter((a) => {
            return a.assessorId === this.userId && a.dateCanceled === null
          })
          .sort(function (a, b) {
            return new Date(b.dateCreated) - new Date(a.dateCreated);
          });
      }
    },
  },
  methods: {
    openAssessment(assessmentId) {
      this.$emit("open-assessment", assessmentId);
    },
    senderName(id) {
      return this.users.find((user) => {
        return user.id === id;
      }).name;
    },
  },
};
</script>
