<template>
  <div>
    <Loading v-if="loading" />
    <div v-else class="dashboard">
      <div style="display: flex">
        <div class="user-welcome">Hello, {{ user.name }}!</div>
      </div>
      <div
        style="
          display: flex;
          justify-content: space-around;
          align-items: center;
        "
      >
        <div style="text-align: center; margin-left: 5%">
          <div
            style="cursor: pointer"
            class="incomplete-self-assessment-container"
            @click="openAssessmentOverview()"
            v-if="completedSelfAssessments.length > 0"
          >
            <AssessmentOverview
              :dashboard="true"
              :usersAssessments="completedSelfAssessments"
              :usersAssessmentDetails="assessmentDetailsList"
              :userId="user.id"
            />
          </div>
          <div v-else>
            <v-img
              :src="require('../assets/assessmentPlaceholder.png')"
              class="my-3"
              contain
              height="200"
            />
            <br />
            You'll see your assessment results here once you've taken the self
            assessment.
          </div>
        </div>
        <div
          class="survey-lists"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            text-align: center;
            margin-top: 60px;
            margin-right: 5%;
          "
        >
          <div
            v-if="hasIncompleteSelfAssessment"
            style="display: flex; justify-content: center"
          >
            <div class="self-assessment-request">
              <div>You have a new self assessment request</div>
              <div
                class="complete-self-assesment-button"
                style="display: flex; justify-content: center; margin-top: 20px"
              >
                <v-btn
                  color="secondary"
                  elevation="2"
                  @click="loadAssessment(selfAssessment.id)"
                  style="width: 240px"
                >
                  {{ getSelfAssessmentButtonText(selfAssessment.id) }}
                </v-btn>
              </div>
            </div>
          </div>
          <div style="" class="notification-container">
            <AssessmentsToComplete
              :assessments="assessmentsToComplete"
              @open-assessment="loadAssessment"
              :userId="user.id"
              :users="users"
            />
          </div>
          <div
            style="
              display: flex;
              width: 33%;
              margin-right: 8%;
              flex-direction: column;
            "
          >
            <v-btn
              color="#6bc494"
              style="color: white; width: 500px; margin-top: 30px;"
              elevation="2"
              @click="toggleModal"
            >
              Create an assessment request
            </v-btn>

          </div>
          <!-- <div
            style="
              width: 500px;
              border: 1px solid #d3d3d3;
              border-radius: 10px;
              min-height: 250px;
              margin-top: 40px;
              box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
                rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
                rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
            "
            class="notification-container"
          >
            <CompletedAssessments
              :users="users"
              :requestedAssessments="requestedAssessments"
              @reload-assessment-requests="loadAssessmentRequests"
              :userId="user.id"
            />
          </div> -->
        </div>
      </div>
    </div>
    <div>
      <v-alert v-if="showHireDateToast" color="#E5EDF8" border="top">
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <div style="margin-bottom: 30px">
              <strong>First time here?</strong> <br />Take a second to enter
              your estimated hire date
            </div>
            <div
              style="margin-top: 10px; display: flex; justify-content: center"
            >
              <v-btn
                style="margin-top: 30px"
                color="#0098FE"
                elevation="2"
                outlined
                @click="updateUserHireDate()"
                >Submit</v-btn
              >
            </div>

            <v-text-field
              v-model="hireDateFormatted"
              label="Date"
              hint="MM/DD/YYYY format"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              @blur="hireDate = parseDate(hireDate)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="hireDate"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-alert>
      <v-alert
        v-if="showSuccesstoast"
        color="green"
        style="color: white; text-align: center"
        dismissible
        >Thanks! We won't ask you that again. If you've made an error, please
        notify Katie Hicks. <br />
        <br />
        Hope you're having a great day!</v-alert
      >
    </div>
    <SendAssessmentRequestModal
      v-if="!this.loading"
      :users="users"
      :show-modal="showModal"
      :isAdmin="true"
      :userId="user.id"
      @reload-assessment-requests="loadAllAssessments"
      @toggle-modal="toggleModal"
    />
  </div>
</template>

<script>
// import CompletedAssessments from "./CompletedAssessments.vue";7
import {
  getAllUsersAssessments,
  getExistingAssessmentDetails,
  getAssessmentRequests,
  getAllAssessments,
} from "../../api/endpoints/assessment";

import { getAllUsers, setUserHireDate } from "../../api/endpoints/user";
import Loading from "./Loading.vue";
import AssessmentOverview from "./AssessmentOverview.vue";
import AssessmentsToComplete from "./AssessmentsToComplete.vue";
import SendAssessmentRequestModal from "./SendAssessmentRequestModal.vue";

export default {
  components: {
    AssessmentOverview,
    AssessmentsToComplete,
    Loading,
    SendAssessmentRequestModal
  },
  computed: {
    hasIncompleteSelfAssessment() {
      return !!this.assessments.find(
        (assessment) =>
          assessment.assessedId === this.user.id &&
          assessment.assessorId === this.user.id &&
          !assessment.dateCompleted
      );
    },
    selfAssessment() {
      return this.assessments.find(
        (assessment) =>
          assessment.assessedId === this.user.id &&
          assessment.assessorId === this.user.id &&
          !assessment.dateCompleted
      );
    },
    assessmentsToComplete() {
      return this.assessmentRequests.filter(
        (assessment) =>
          !assessment.dateCompleted && assessment.assessedId !== this.user.id
      );
    },
    completedSelfAssessments() {
      if (this.user) {
        return this.assessments.filter(
          (assessment) =>
            assessment.assessedId === this.user.id &&
            assessment.assessorId === this.user.id &&
            assessment.dateCompleted
        );
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      loading: true,
      allAssessments: [],
      assessment: null,
      assessments: [],
      assessmentDetailsList: [],
      assessmentRequests: null,
      requestedAssessments: null,
      user: null,
      users: null,
      hireDate: null,
      showHireDateToast: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      hireDateFormatted: this.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menu1: false,
      menu2: false,
      showSuccesstoast: false,
      showModal: false
    };
  },
  methods: {
    async init() {
      // get all useres to populate the request dropdown
      this.users = await getAllUsers();

      this.user = this.users.find(
        (user) => user.id === this.$route.query.userId
      );

      // get all of this users assessments
      this.assessments = await getAllUsersAssessments(this.user.id);

      this.assessmentRequests = await getAssessmentRequests(this.user.id);

      const assessmentDetailsList = [];
      this.assessments.forEach((assessment) => {
        assessmentDetailsList.push(getExistingAssessmentDetails(assessment.id));
      });

      this.assessmentDetailsList = assessmentDetailsList;

      this.shouldShowHireDateToast();
      this.loading = false;
    },
    loadAssessment(assessmentId) {
      this.$router
        .push({
          name: "assessment",
          query: {
            assessmentId: assessmentId,
          },
        })
        .catch((e) => {
          console.log(e);
        });
    },
    shouldShowHireDateToast() {
      if (this.users?.length > 0 && this.user.id) {
        const user = this.users.find((user) => user.id === this.user.id);
        if (user.hireDate === null) {
          this.showHireDateToast = true;
        }
      }
    },
    async updateUserHireDate() {
      await setUserHireDate(this.user.id, this.hireDateFormatted);
      this.showHireDateToast = false;
      this.showSuccesstoast = true;
    },
    openAssessmentOverview() {
      this.loading = true;
      this.$router.push({
        name: "assessmentOverview",
        query: {
          userId: this.user.id,
        },
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    getSelfAssessmentButtonText(id) {
      const existingAssessment = this.assessments.find(
        (assessment) => assessment.id === id
      );
      if (existingAssessment) {
        if (existingAssessment.dateStarted) {
          return "Continue Self Assessment";
        } else {
          return "Begin Self Assessment";
        }
      } else {
        return "Begin Self Assessment";
      }
    },
    openAdmin() {
      this.$router
        .push({ name: "admin", query: { userId: this.user.id } })
        .catch(() => {});
    },
    async loadAllAssessments() {
      this.allAssessments = await getAllAssessments();
    },
    toggleModal() {
      this.showModal = !this.showModal;
    }
  },
  async created() {
    await this.init();
  },
  watch: {
    hireDate() {
      this.hireDateFormatted = this.formatDate(this.hireDate);
    },
  },
};
</script>
<style scoped>
.user-welcome {
  display: flex;
  justify-content: center;
  margin: 25px;
  margin-left: 45%;
  font-size: 1.5rem;
}
.toast:not(.show) {
  display: block;
}
.notification-container {
  width: 500px;
  border-radius: 10px;
  min-height: 250px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.self-assessment-request {
  width: 500px;
  border-radius: 10px;
  margin-bottom: 50px;
  min-height: 175px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

.v-alert {
  position: fixed;
  top: 1%;
  right: 1%;
  width: 445px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px !important;
}

.v-expansion-panel-header {
  font-size: 12px;
}
</style>
